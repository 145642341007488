import React, { useState, useEffect } from 'react';
import { PageProps, graphql } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';
import GatsbyImage from 'gatsby-image';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import brokerCentreMenu from '../../../../data/page-menus/broker-centre';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import './style.scss';
import { useLocaleContext } from '../../../../context/Locale';
import Input, { InputType } from '../../../../components/primitives/Input/Input';
import { IconTypes } from '../../../../components/Icon/Icon';
import { formatCurrency } from '../../../../shared/utils/formatNumbers';

type Props = PageProps & WithTranslation;

const SlidingScaleCalculator: React.FC<Props> = (props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const ranges = [
    {
      rate: 0.8,
      threshold: { label: t('slidingScaleCalculator.zeroToMillionAndHalf'), value: 1500000 },
    },
    {
      rate: 0.65,
      threshold: { label: t('slidingScaleCalculator.overMillionAndHalf'), value: 1230769.22 },
    },
    // {
    //   rate: 0.5,
    //   threshold: { label: t('slidingScaleCalculator.overMillionAndHalf'), value: Infinity },
    // },
  ];

  const [maximumLendingValue, setMaximumLendingValue] = useState<{
    total: number;
    resultValues: number[];
  }>({ total: 0, resultValues: [] });

  const calculateMaximumLendingValue = (
    amount: number
  ): { total: number; resultValues: number[] } => {
    if (amount >= 2730769.22) {
      return {
        total: 2000000,
        resultValues: [1200000, 800000],
      };
    }

    // let total = 0;
    // let remainderAmount = amount;
    // const resultValues: number[] = [];
    // ranges.forEach((range, i) => {
    //   if (!remainderAmount) {
    //     resultValues.push(0);
    //   }

    //   let calculatedAmount = Math.min(amount, range.threshold.value);
    //   const tempRoundOff = (resultValues[i - 1] || 0) + remainderAmount; // prev + curr
    //   calculatedAmount = Math.min(calculatedAmount, tempRoundOff);
    //   calculatedAmount = (-resultValues[i - 1] || 0) + calculatedAmount; // prev - curr

    //   const tempLendingValue = calculatedAmount * range.rate;
    //   resultValues.push(tempLendingValue);
    //   total += tempLendingValue;
    //   remainderAmount -= calculatedAmount;
    // });

    let remainder = amount;

    const result = ranges.reduce(
      (current: { total: number; resultValues: number[] }, range) => {
        const { total, resultValues } = current;

        if (remainder <= 0) {
          return { total, resultValues: [...resultValues, 0] };
        }

        const min = Math.min(remainder, range.threshold.value);
        const currentResult = range.rate * min;
        (resultValues as number[]).push(currentResult);
        remainder -= range.threshold.value;

        return { total: total + currentResult, resultValues };
      },
      { total: 0, resultValues: [] }
    );

    return result;
  };

  useEffect(() => {
    const result = calculateMaximumLendingValue(0);
    setMaximumLendingValue(result);
  }, []);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('slidingScaleCalculator.seo.title'),
    },
    page: {
      title: t('slidingScaleCalculator.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const handleAppraisedValueChange = (strNum: string) => {
    const parsedNum = +strNum || 0;
    const result = calculateMaximumLendingValue(parsedNum);
    setMaximumLendingValue(result);
  };

  const getLendingValuesRows = () => {
    return ranges.map((range, i) => {
      return (
        <tr key={`tr-${i}`}>
          <td> {range.threshold.label} </td>
          <td> {range.rate * 100}% </td>
          <td> {formatCurrency(maximumLendingValue.resultValues[i] || 0, activeLocaleTag)} </td>
        </tr>
      );
    });
  };

  const image = (data as any)[`imageMMC_${activeLocaleTag}`].childImageSharp.fluid;

  return (
    <Layout options={layoutOptions} className="SlidingScaleCalculator">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p>{t(`slidingScaleCalculator.description`)}</p>
            <ul className="SlidingScaleCalculator__lending-value-list">
              <p>{t(`slidingScaleCalculator.calculateLendingValueBlocks.heading`)}</p>
              {(t(`slidingScaleCalculator.calculateLendingValueBlocks.blocks`, {
                returnObjects: true,
              }) as string[]).map((str, i) => {
                return <li key={`slidingCalcPara-${i}`}>{str}</li>;
              })}
            </ul>

            <Input
              id="appraisedValue"
              className="SlidingScaleCalculator__generic-gap"
              options={{
                type: InputType.NUMBER,
                label: t('slidingScaleCalculator.appraisedValueLabel'),
                inputProps: {
                  defaultValue: 0,
                  min: 0,
                  maxLength: 10000000000000,
                  step: 'any',
                  pattern: '[0-9]*',
                  inputMode: 'decimal',
                },
                groupOptions: {
                  rightSide: {
                    icon: IconTypes.DOLLAR,
                  },
                },
              }}
              onChange={handleAppraisedValueChange}
            />
            <div className="SlidingScaleCalculator__generic-gap">
              <table>
                <tbody>{getLendingValuesRows()}</tbody>
              </table>
            </div>
            <div className="SlidingScaleCalculator__result">
              <p>{t('slidingScaleCalculator.totalMaximumValueResult')}</p>
              <p className="h3">{formatCurrency(maximumLendingValue.total, activeLocaleTag)}</p>
            </div>

            <GatsbyImage
              className="SlidingScaleCalculator__result__footer-image"
              alt="Image"
              fluid={image}
              style={{ position: 'relative', width: '100%' }}
              imgStyle={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
            />
            <p className="small">{t('slidingScaleCalculator.footerNote')}</p>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={brokerCentreMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    imageMMC_en: file(relativePath: { eq: "maximum-mortgage-calculator-24_Feb_2022-en.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageMMC_fr: file(relativePath: { eq: "maximum-mortgage-calculator-24_Feb_2022-fr.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(SlidingScaleCalculator);
